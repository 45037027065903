import React, {useLayoutEffect} from "react"
import Loadable from "react-loadable"
import "styles/global.scss"
import { Routes, Route } from "react-router-dom"
import { BrowserRouter } from "react-router-dom"
import Sidebar from "components/Navbar/Side"
export const AppContext = React.createContext()

const loader = () => <div>Loading.</div>


const HomeLazy = Loadable({
  loader: () => import("containers/Home"),
  loading: loader,
})



const Index = () => {
  
  return (
    <>
        <HomeLazy/>
    </>
  )
}
export default Index
