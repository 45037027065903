import React from "react"
import cn from "classnames"
import Wrapper from "./styled"
const HamburgerIcon = ({ isOpen, setIsOpen, color = '#FFFFFF' }) => {
  

  return (
    <Wrapper color={color}>
      <svg
        fill="currentColor"
        className={`ham hamRotate  ${cn({ active: isOpen })}`}
        viewBox="0 0 100 100"
        width="100%"
      >
        <path
         
          className="line top"
          d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20"
        />
        <path  className="line middle" d="m 70,50 h -40" />
        <path
        
          className="line bottom"
          d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20"
        />
      </svg>
    </Wrapper>
  )
}

export default HamburgerIcon
