import styled from "styled-components"

const Wrapper = styled.div`
 cursor: pointer !important;
  .ham {
   
    -webkit-tap-highlight-color: transparent;
    transition: transform 600ms;
    transition-delay: 200ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  
  .hamRotate.active {
    transform: rotate(45deg);
  }

  .line {
    fill: none;
    transition-delay: 200ms;
    transition: stroke-dasharray 600ms, stroke-dashoffset 600ms;
    stroke-width: 5.5;
    stroke: currentColor;
    stroke-linecap: round;
  }

  .ham .top {
    stroke-dasharray: 40 121;
  }
  .ham .bottom {
    stroke-dasharray: 40 121;
  }
  .ham.active .top {
    stroke-dashoffset: -68px;
  }
  .ham.active .bottom {
    stroke-dashoffset: -68px;
  }
`

export default Wrapper
