import styled from "styled-components"

const Container = styled.div`
  .menu-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 3vw;
    height: 100%;
    border-right: 2px solid white;
    z-index: 100;
    mix-blend-mode: difference;
  }

  .menu-open {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background-color: black;
  }

  .menu-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    color: white;
    font-weight: 500;
  
 
    li {
      a{
        overflow: hidden;
        display: inline-block;
      }
    }
  }

  @include media(">=tablet") {
    .menu-list {
        justify-content: flex-start;
    }
  }
`

export default Container
